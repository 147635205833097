import React from 'react';

const RegisterSvgTest = () => {
    return (
<svg width="636" height="216" viewBox="0 0 636 216" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_1_4263" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="636" height="216">
<rect width="636" height="216" rx="20" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1_4263)">
<path d="M-7.49997 156C-22.8333 93.1667 16.4 -18.3 296 38.5C575.6 95.3 684.5 -27.5 704 -96" stroke="#F24822"/>
<path d="M-11.2497 150.629C49.9207 85.27 206.579 -20.3228 343.849 80.1775C481.119 180.678 568.645 39.5149 595.25 -43.629" stroke="#01FE39"/>
<path d="M-8.01209 155.917C-21.854 92.9095 19.6804 -18.6331 296.553 39.2583C573.426 97.1496 681.869 -25.9634 701.482 -94.7563" stroke="#ED4C22"/>
<path d="M-8.48016 155.832C-20.83 92.6551 23.0058 -18.9558 297.148 40.0176C571.291 98.991 679.279 -24.437 699.005 -93.5227" stroke="#E94F23"/>
<path d="M-8.9034 155.745C-19.7607 92.4036 26.377 -19.268 297.786 40.7782C569.196 100.824 676.73 -22.9205 696.571 -92.2987" stroke="#E45323"/>
<path d="M-9.28115 155.656C-18.6454 92.1552 29.7944 -19.5694 298.468 41.5403C567.141 102.65 674.223 -21.4137 694.18 -91.0843" stroke="#DF5624"/>
<path d="M-9.61283 155.566C-17.4835 91.9103 33.2588 -19.8597 299.193 42.3042C565.128 104.468 671.759 -19.9165 691.833 -89.8793" stroke="#DA5A24"/>
<path d="M-9.89799 155.474C-16.2746 91.669 36.7705 -20.1388 299.963 43.07C563.156 106.279 669.338 -18.4284 689.53 -88.6832" stroke="#D65D25"/>
<path d="M-10.136 155.381C-15.018 91.4317 40.33 -20.4062 300.778 43.8382C561.226 108.083 666.96 -16.9493 687.271 -87.4959" stroke="#D16125"/>
<path d="M-10.3273 155.287C-13.7142 91.1984 43.9372 -20.662 301.638 44.6087C559.339 109.879 664.626 -15.4792 685.057 -86.3173" stroke="#CC6526"/>
<path d="M-10.4715 155.193C-12.3629 90.9696 47.592 -20.9056 302.543 45.382C557.494 111.67 662.336 -14.0174 682.888 -85.1469" stroke="#C76826"/>
<path d="M-10.5687 155.099C-10.9641 90.7454 51.2945 -21.137 303.492 46.1582C555.69 113.453 660.089 -12.564 680.764 -83.9847" stroke="#C36C26"/>
<path d="M-10.6193 155.004C-9.51827 90.5261 55.0443 -21.3559 304.487 46.9376C553.929 115.231 657.885 -11.1187 678.683 -82.8303" stroke="#BE6F27"/>
<path d="M-10.6425 154.909C-8.04464 90.3114 58.8221 -21.5624 305.506 47.72C552.19 117.002 655.706 -9.68162 676.628 -81.684" stroke="#B97327"/>
<path d="M-10.6651 154.813C-6.57004 90.101 62.601 -21.7571 306.524 48.5049C550.447 118.767 653.524 -8.25326 674.572 -80.5461" stroke="#B57628"/>
<path d="M-10.6874 154.716C-5.09468 89.8949 66.3807 -21.9398 307.54 49.2924C548.7 120.525 651.339 -6.83361 672.513 -79.4168" stroke="#B07A28"/>
<path d="M-10.7094 154.619C-3.61858 89.693 70.1614 -22.1107 308.555 50.0824C546.948 122.275 649.151 -5.42269 670.453 -78.2959" stroke="#AB7E29"/>
<path d="M-10.731 154.521C-2.14175 89.4954 73.9429 -22.2696 309.568 50.875C545.192 124.02 646.96 -4.0205 668.391 -77.1836" stroke="#A68129"/>
<path d="M-10.7522 154.422C-0.664191 89.302 77.7251 -22.4167 310.578 51.67C543.432 125.757 644.766 -2.62704 666.327 -76.0798" stroke="#A2852A"/>
<path d="M-10.7731 154.323C0.814077 89.1128 81.5082 -22.5518 311.588 52.4676C541.667 127.487 642.57 -1.24234 664.261 -74.9844" stroke="#9D882A"/>
<path d="M-10.7936 154.222C2.29305 88.928 85.292 -22.6751 312.595 53.2677C539.898 129.21 640.37 0.133628 662.193 -73.8976" stroke="#988C2A"/>
<path d="M-10.8134 154.121C3.77294 88.7473 89.0767 -22.7864 313.601 54.0703C538.125 130.927 638.168 1.50084 660.124 -72.8193" stroke="#948F2B"/>
<path d="M-10.8332 154.02C5.25328 88.571 92.8619 -22.8858 314.605 54.8754C536.347 132.637 635.963 2.8593 658.054 -71.7496" stroke="#8F932B"/>
<path d="M-10.8526 153.917C6.73427 88.399 96.6478 -22.9732 315.607 55.6832C534.565 134.34 633.756 4.2091 655.981 -70.6882" stroke="#8A962C"/>
<path d="M-10.8717 153.814C8.21592 88.2311 100.434 -23.0489 316.607 56.4932C532.779 136.035 631.545 5.5499 653.907 -69.6356" stroke="#859A2C"/>
<path d="M-10.8903 153.71C9.69821 88.0676 104.221 -23.1125 317.605 57.306C530.989 137.724 629.332 6.88216 651.831 -68.5913" stroke="#819E2D"/>
<path d="M-10.9086 153.605C11.1811 87.9082 108.009 -23.1644 318.602 58.121C529.194 139.406 627.117 8.2054 649.754 -67.5557" stroke="#7CA12D"/>
<path d="M-10.9265 153.5C12.6647 87.7533 111.797 -23.2041 319.596 58.9387C527.396 141.082 624.898 9.5201 647.675 -66.5285" stroke="#77A52E"/>
<path d="M-10.9441 153.394C14.1488 87.6026 115.586 -23.232 320.589 59.7588C525.593 142.75 622.677 10.8259 645.594 -65.5099" stroke="#72A82E"/>
<path d="M-10.9613 153.287C15.6336 87.4561 119.375 -23.248 321.58 60.5815C523.786 144.411 620.454 12.1229 643.512 -64.4998" stroke="#6EAC2E"/>
<path d="M-10.9781 153.179C17.1189 87.3139 123.164 -23.2521 322.569 61.4065C521.975 146.065 618.227 13.4111 641.428 -63.4983" stroke="#69AF2F"/>
<path d="M-10.9945 153.071C18.6048 87.176 126.954 -23.2442 323.557 62.2341C520.159 147.712 615.999 14.6904 639.343 -62.5053" stroke="#64B32F"/>
<path d="M-11.0105 152.962C20.0912 87.0424 130.744 -23.2245 324.542 63.0641C518.34 149.353 613.768 15.961 637.257 -61.5209" stroke="#60B730"/>
<path d="M-11.026 152.852C21.5785 86.913 134.535 -23.1927 325.526 63.8966C516.517 150.986 611.534 17.2228 635.169 -60.545" stroke="#5BBA30"/>
<path d="M-11.0413 152.741C23.066 86.7879 138.326 -23.1491 326.508 64.7316C514.689 152.612 609.298 18.4757 633.079 -59.5776" stroke="#56BE31"/>
<path d="M-11.0562 152.63C24.5541 86.6671 142.117 -23.0935 327.487 65.569C512.858 154.232 607.059 19.7198 630.988 -58.6188" stroke="#51C131"/>
<path d="M-11.0707 152.518C26.0427 86.5506 145.909 -23.0261 328.465 66.4088C511.022 155.844 604.818 20.9551 628.896 -57.6686" stroke="#4DC532"/>
<path d="M-11.0848 152.406C27.5318 86.4383 149.7 -22.9466 329.441 67.2512C509.182 157.449 602.574 22.1815 626.802 -56.7269" stroke="#48C832"/>
<path d="M-11.0986 152.292C29.0213 86.3304 153.492 -22.8553 330.415 68.096C507.339 159.047 600.328 23.3991 624.708 -55.7938" stroke="#43CC32"/>
<path d="M-11.1119 152.178C30.5114 86.2267 157.284 -22.752 331.388 68.9432C505.491 160.638 598.08 24.6079 622.611 -54.8692" stroke="#3ED033"/>
<path d="M-11.1249 152.063C32.0019 86.1275 161.076 -22.6366 332.358 69.7929C503.64 162.222 595.829 25.8079 620.514 -53.9531" stroke="#3AD333"/>
<path d="M-11.1375 151.948C33.4929 86.0323 164.868 -22.5096 333.326 70.6449C501.784 163.799 593.576 26.9989 618.415 -53.0457" stroke="#35D734"/>
<path d="M-11.1497 151.831C34.9843 85.9416 168.66 -22.3703 334.293 71.4996C499.925 165.369 591.321 28.1812 616.315 -52.1467" stroke="#30DA34"/>
<path d="M-11.1615 151.714C36.4762 85.8551 172.453 -22.2193 335.257 72.3565C498.061 166.932 589.063 29.3545 614.213 -51.2564" stroke="#2CDE35"/>
<path d="M-11.1729 151.597C37.9684 85.7729 176.245 -22.0563 336.22 73.2159C496.194 168.488 586.803 30.519 612.111 -50.3746" stroke="#27E135"/>
<path d="M-11.184 151.478C39.4611 85.695 180.037 -21.8814 337.18 74.0777C494.323 170.037 584.541 31.6746 610.007 -49.5014" stroke="#22E536"/>
<path d="M-11.1943 151.359C40.9545 85.6214 183.829 -21.6946 338.139 74.9419C492.448 171.578 582.277 32.8213 607.902 -48.6368" stroke="#1DE936"/>
<path d="M-11.2046 151.239C42.4479 85.5521 187.621 -21.4958 339.096 75.8085C490.57 173.113 580.01 33.9591 605.796 -47.7807" stroke="#19EC36"/>
<path d="M-11.2144 151.118C43.9418 85.4871 191.413 -21.2851 340.05 76.6775C488.687 174.64 577.742 35.0881 603.689 -46.9332" stroke="#14F037"/>
<path d="M-11.2238 150.997C45.436 85.4264 195.205 -21.0624 341.003 77.5489C486.801 176.16 575.471 36.2081 601.581 -46.0943" stroke="#0FF337"/>
<path d="M-11.2329 150.875C46.9306 85.3699 198.997 -20.8278 341.954 78.4227C484.911 177.673 573.198 37.3193 599.472 -45.264" stroke="#0BF738"/>
<path d="M-11.2415 150.752C48.4254 85.3178 202.788 -20.5813 342.902 79.2989C483.017 179.179 570.923 38.4216 597.361 -44.4422" stroke="#06FA38"/>
<path d="M-11 155.53C28.4098 102.447 163.404 23.6459 388.102 133.106C612.799 242.567 560.854 59.3996 506.794 -45.8667" stroke="#011AFE"/>
<path d="M-11 149.037C50.1704 83.6782 206.829 -21.9146 344.099 78.5857C481.369 179.086 568.895 37.9232 595.5 -45.2208" stroke="#01FE39"/>
<path d="M-10.8909 155.37C29.247 101.958 164.994 22.3643 386.88 131.284C608.765 240.203 561.193 58.7896 509.672 -45.532" stroke="#0122F8"/>
<path d="M-10.7892 155.206C30.0753 101.461 166.572 21.0696 385.644 129.465C604.717 237.859 561.528 58.1739 512.549 -45.2183" stroke="#0129F1"/>
<path d="M-10.6945 155.039C30.895 100.956 168.138 19.762 384.396 127.649C600.653 235.537 561.857 57.5527 515.426 -44.9253" stroke="#0130EB"/>
<path d="M-10.6069 154.868C31.706 100.443 169.692 18.4414 383.134 125.838C596.576 233.235 562.181 56.9257 518.303 -44.6533" stroke="#0138E4"/>
<path d="M-10.5267 154.694C32.5079 99.9218 171.234 17.1079 381.859 124.031C592.484 230.953 562.5 56.2931 521.179 -44.4023" stroke="#013FDE"/>
<path d="M-10.4535 154.516C33.3013 99.3927 172.763 15.7618 380.57 122.227C588.378 228.693 562.813 55.655 524.055 -44.1721" stroke="#0146D8"/>
<path d="M-10.3875 154.335C34.0857 98.8556 174.28 14.4026 379.268 120.428C584.257 226.453 563.122 55.0111 526.93 -43.9629" stroke="#014ED1"/>
<path d="M-10.3291 154.151C34.8611 98.3105 175.784 13.0308 377.953 118.632C580.123 224.234 563.425 54.3617 529.804 -43.7747" stroke="#0155CB"/>
<path d="M-10.2776 153.963C35.6277 97.7576 177.276 11.6463 376.625 116.841C575.974 222.036 563.722 53.7067 532.678 -43.6073" stroke="#015CC5"/>
<path d="M-10.2333 153.771C36.3853 97.1968 178.755 10.2491 375.284 115.054C571.812 219.859 564.015 53.0462 535.55 -43.4609" stroke="#0164BE"/>
<path d="M-10.1966 153.577C37.1338 96.6284 180.221 8.8395 373.929 113.271C567.636 217.703 564.302 52.3802 538.422 -43.3354" stroke="#016BB8"/>
<path d="M-10.1669 153.379C37.8734 96.052 181.675 7.41724 372.561 111.493C563.447 215.569 564.584 51.7086 541.292 -43.2308" stroke="#0172B2"/>
<path d="M-10.1445 153.178C38.604 95.468 183.117 5.98251 371.18 109.719C559.243 213.455 564.861 51.0315 544.161 -43.1472" stroke="#017AAB"/>
<path d="M-10.1296 152.973C39.3252 94.8763 184.545 4.53548 369.786 107.949C555.026 211.363 565.131 50.3491 547.029 -43.0845" stroke="#0181A5"/>
<path d="M-10.1218 152.766C40.0376 94.2769 185.961 3.07595 368.379 106.184C550.796 209.292 565.397 49.661 549.895 -43.0428" stroke="#01889E"/>
<path d="M-10.1213 152.555C40.7409 93.6699 187.364 1.60412 366.958 104.423C546.553 207.242 565.658 48.9675 552.761 -43.022" stroke="#019098"/>
<path d="M-10.1281 152.341C41.4349 93.0553 188.754 0.120007 365.525 102.667C542.296 205.213 565.912 48.2686 555.624 -43.0221" stroke="#019792"/>
<path d="M-10.1425 152.124C42.1195 92.4333 190.131 -1.37618 364.078 100.915C538.026 203.206 566.162 47.5643 558.486 -43.0431" stroke="#019E8B"/>
<path d="M-10.1641 151.904C42.7951 91.8037 191.495 -2.88465 362.619 99.1681C533.744 201.221 566.405 46.8546 561.346 -43.0851" stroke="#01A685"/>
<path d="M-10.1929 151.681C43.4615 91.1666 192.846 -4.40523 361.147 97.4257C529.448 199.257 566.644 46.1395 564.204 -43.148" stroke="#01AD7F"/>
<path d="M-10.2294 151.455C44.1183 90.5222 194.183 -5.93774 359.661 95.6883C525.139 197.314 566.876 45.4191 567.06 -43.2318" stroke="#01B578"/>
<path d="M-10.2731 151.226C44.766 89.8703 195.508 -7.48236 358.163 93.9556C520.818 195.393 567.104 44.6933 569.915 -43.3366" stroke="#01BC72"/>
<path d="M-10.3241 150.994C45.4044 89.2111 196.819 -9.03892 356.652 92.2277C516.484 193.494 567.325 43.9621 572.767 -43.4623" stroke="#01C36C"/>
<path d="M-10.3827 150.76C46.0331 88.5446 198.117 -10.6074 355.127 90.5047C512.137 191.617 567.541 43.2256 575.617 -43.609" stroke="#01CB65"/>
<path d="M-10.4485 150.522C46.6527 87.8708 199.402 -12.1877 353.59 88.7866C507.779 189.761 567.752 42.4838 578.465 -43.7765" stroke="#01D25F"/>
<path d="M-10.5218 150.281C47.2628 87.19 200.674 -13.7796 352.041 87.0738C503.407 187.927 567.957 41.7369 581.311 -43.9649" stroke="#01D959"/>
<path d="M-10.6027 150.038C47.8632 86.5018 201.932 -15.3834 350.478 85.3658C499.024 186.115 568.156 40.9845 584.153 -44.1743" stroke="#01E152"/>
<path d="M-10.6908 149.792C48.4544 85.8065 203.176 -16.9989 348.902 83.663C494.628 184.325 568.349 40.227 586.994 -44.4046" stroke="#01E84C"/>
<path d="M-10.7863 149.543C49.0361 85.1042 204.407 -18.6258 347.314 81.9654C490.22 182.557 568.537 39.4643 589.832 -44.6557" stroke="#01EF45"/>
<path d="M-10.8895 149.291C49.6079 84.3947 205.625 -20.2644 345.713 80.2729C485.8 180.81 568.719 38.6963 592.667 -44.9278" stroke="#01F73F"/>
</g>
</svg>

    );
};

export default RegisterSvgTest;