// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0px 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.log_out_btn_wrap {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.plus_order {
  position: fixed;
  top: 60%;
  right: 8%;
  background-color: #F24822;
  color: #FFF;
}`, "",{"version":3,"sources":["webpack://./src/style/App.scss","webpack://./src/style/colors.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EAEA,mBAAA;EACA,kBAAA;EAGA,iBAAA;AAJF;;AAOA;EACE,cAAA;EACA,oBAAA;AAJF;;AAOA;EACE;IACE,4CAAA;EAJF;AACF;AAOA;EACE,yBAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,6BAAA;EACA,YAAA;AALF;;AAQA;EACE,cAAA;AALF;;AAUA;EACE;IACE,uBAAA;EAPF;EASA;IACE,yBAAA;EAPF;AACF;AAWA;EACE,WAAA;EACA,aAAA;EACA,yBAAA;AATF;;AAaA;EACE,eAAA;EACA,QAAA;EACA,SAAA;EACA,yBC5Da;ED6Db,WC/DY;ADqDd","sourcesContent":["@import './colors.scss';\r\n\r\n.App {\r\n  display: flex;\r\n  flex-direction: column;\r\n  // justify-content: center;\r\n  align-items: center;\r\n  text-align: center;\r\n  // background-color: $light_grey_color;\r\n  // height: 100vh;\r\n  padding: 0px 20px;\r\n}\r\n\r\n.App-logo {\r\n  height: 40vmin;\r\n  pointer-events: none;\r\n}\r\n\r\n@media (prefers-reduced-motion: no-preference) {\r\n  .App-logo {\r\n    animation: App-logo-spin infinite 20s linear;\r\n  }\r\n}\r\n\r\n.App-header {\r\n  background-color: #282c34;\r\n  min-height: 100vh;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n  font-size: calc(10px + 2vmin);\r\n  color: white;\r\n}\r\n\r\n.App-link {\r\n  color: #61dafb;\r\n}\r\n\r\n\r\n\r\n@keyframes App-logo-spin {\r\n  from {\r\n    transform: rotate(0deg);\r\n  }\r\n  to {\r\n    transform: rotate(360deg);\r\n  }\r\n}\r\n\r\n\r\n.log_out_btn_wrap{\r\n  width: 100%;\r\n  display: flex;\r\n  justify-content: flex-end;\r\n}\r\n\r\n\r\n.plus_order{\r\n  position: fixed;\r\n  top: 60%;\r\n  right: 8%;\r\n  background-color: $orange_color;\r\n  color: $white_color;\r\n}","$white_color: #FFF;\r\n$light_grey_color: #EEE;\r\n$orange_color: #F24822;\r\n$light_orange_color: #FFAA97;\r\n$dark_color: #1E1E1E;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
