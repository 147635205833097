// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search_wrap_input {
  width: 100%;
  height: 50px;
  margin: 5px 0 20px 0;
}
.search_wrap_input input {
  width: 90%;
  max-width: 600px;
  height: 100%;
  border-radius: 8px;
  padding: 10px;
  font-size: 20px;
  border: 1px solid #adadad;
}`, "",{"version":3,"sources":["webpack://./src/style/SearchQuery.scss"],"names":[],"mappings":"AAEA;EACI,WAAA;EACA,YAAA;EACA,oBAAA;AADJ;AAEI;EACI,UAAA;EACA,gBAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,eAAA;EACA,yBAAA;AAAR","sourcesContent":["@import './colors.scss';\r\n\r\n.search_wrap_input{\r\n    width: 100%;\r\n    height: 50px;\r\n    margin: 5px 0 20px 0;\r\n    input{\r\n        width: 90%;\r\n        max-width: 600px;\r\n        height: 100%;\r\n        border-radius: 8px;\r\n        padding: 10px;\r\n        font-size: 20px;\r\n        border: 1px solid #adadad;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
