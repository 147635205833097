// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination-controls {
  display: flex;
  justify-content: center;
  gap: 50px;
  padding: 50px;
}
.pagination-controls select {
  width: 100px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #adadad;
}`, "",{"version":3,"sources":["webpack://./src/style/Pagination.scss"],"names":[],"mappings":"AAGA;EACI,aAAA;EACA,uBAAA;EACA,SAAA;EACA,aAAA;AAFJ;AAGI;EACI,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;AADR","sourcesContent":["@import './colors.scss';\r\n\r\n\r\n.pagination-controls{\r\n    display: flex;\r\n    justify-content: center;\r\n    gap: 50px;\r\n    padding: 50px;\r\n    select{\r\n        width: 100px;\r\n        text-align: center;\r\n        border-radius: 8px;\r\n        border: 1px solid #adadad;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
